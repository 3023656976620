import React from "react"
import Error404Header from "../components/layout/404.header";
import Error404Footer from "../components/layout/404.footer";

// markup
const NotFoundPage = () => {
  return (
      <div  className="d-flex flex-column justify-content-center align-items-center h-100">
      <Error404Header title={'Page non trouvée'}/>
        <main id="content" role="main">
          <div className="container text-center mt-10">
            <div className="mb-4">
              <h1 className="display-1">404</h1>
              <h3 className="mb-1">Something went wrong</h3>
              <p>The page you are looking for does not exist!</p>
            </div>
            <a className="btn btn-primary" href="/">Go back home</a>
          </div>
        </main>
          <Error404Footer/>
      </div>
  )
}

export default NotFoundPage
