import React from "react";

const Error404Footer = () => {

    return (
        <div className="position-absolute bottom-0 start-0 end-0">
            <footer className="container py-4">
                <div className="row align-items-md-center text-center text-md-start">
                    <div className="col-md mb-3 mb-md-0">
                        <p className="fs-5 mb-0">© Unify. 2021 Htmlstream.</p>
                    </div>

                    <div className="col-md d-md-flex justify-content-md-end">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle" href="src/components/layout/404.footer#">
                                    <i className="bi-facebook"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle" href="src/components/layout/404.footer#">
                                    <i className="bi-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle" href="src/components/layout/404.footer#">
                                    <i className="bi-github"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle" href="src/components/layout/404.footer#">
                                    <i className="bi-slack"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default Error404Footer;